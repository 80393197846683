import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"

import Glide from "@glidejs/glide"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  useEffect(() => {
    new Glide(".glide").mount()
  }, [])

  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "fons-lalletera.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        tarrina: file(relativePath: { eq: "lalletera-iogurt-soft.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 350) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        quisom: file(relativePath: { eq: "lalletera-qui-som.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        gelat: file(relativePath: { eq: "productes-gelat.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        granissat: file(relativePath: { eq: "productes-granissat.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        iogurt: file(relativePath: { eq: "productes-iogurt.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        lleona: file(relativePath: { eq: "lalletera-local-lleona.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }

        stfelix: file(relativePath: { eq: "lalletera-local-stfelix.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <SEO title="Gelats i iogurts artesans" />

      <BackgroundImage
        Tag="section"
        className="w-full h-96 flex content-center md:mb-6"
        fluid={data.background.childImageSharp.fluid}
        backgroundColor={`#fff`}
      >
        <div className="max-w-screen-lg flex-1 mx-auto px-4 py-8 md:flex content-center">
          <div className="pt-4 flex md:w-1/2 justify-center">
            <Img
              fluid={data.tarrina.childImageSharp.fluid}
              className="w-32 md:w-64"
            />
          </div>
          <div className="pt-4 flex flex-col content-end justify-center text-center md:text-right">
            <h1 className="font-base text-7xl md:text-8xl">la lletera</h1>
            <p className="font-light text-3xl md:text-4xl">
              gelats i iogurts artesans
            </p>
          </div>
        </div>
      </BackgroundImage>

      <section id="quisom" className="max-w-screen-lg mx-auto px-4 py-8">
        <h2 className="text-lime-500 uppercase font-bold text-3xl pb-5">
          Qui som
        </h2>
        <div className="flex">
          <div className="md:w-2/3 md:pr-8">
            <p className="mb-4">
              La Lletera és una empresa Gironina, didacada a l'elaboració de
              forma artesanal de gelats d'estil italià, iogurts soft i
              granissats naturals. Tots els nostres productes tenen una
              formulació especial i personalitzada.
            </p>
            <p className="mb-4">
              Som establiments propis, no franquiciats, per tal de preservar
              l'estàndard de qualitat que volem.
            </p>
            <p className="mb-4">
              Estem interessats en locals per poder-nos expandir amb o sense la
              participació del propietari. Tots en ciutats de mes de 100.000
              habitats i en zones molt cèntriques. Destaquem el nostre especial
              interès en la ciutat de Barcelona.
            </p>
            <p className="mb-4">
              Tenim diferents formats de botigues, en funció dels m2. Ens pot
              servir un local tipus tub de 25 m2, fins a un de mes de 100 amb
              possibilitats de terrassa.
            </p>
          </div>

          <div className="md:w-1/3">
            <Img
              fluid={data.quisom.childImageSharp.fluid}
              className="w-full h-auto"
            />
          </div>
        </div>
      </section>

      <section id="productes" className="max-w-screen-lg mx-auto px-4 py-8">
        <h2 className="text-lime-500 uppercase font-bold text-3xl pb-8">
          Els nostres productes
        </h2>

        <div className="glide overflow-hidden">
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides flex">
              <li className="glide__slide">
                <Img fluid={data.iogurt.childImageSharp.fluid} />
                <p>
                  Iogurt natural, elaborat amb llet de vaca 100% ecològica.
                  Servit en tarrina i amb un ampli bufet de productes per
                  decar-lo com més t'agradi.
                </p>
              </li>
              <li className="glide__slide">
                <Img fluid={data.granissat.childImageSharp.fluid} />
                <p>
                  Les nostres bateries de granissats, ideals per refrescar-te.
                  Disposem d'una amplia varietat de sabors.
                </p>
              </li>
              <li className="glide__slide">
                <Img fluid={data.gelat.childImageSharp.fluid} />
                <p>
                  Gelats 100% artesans elaborat insitu per els nostres mestres
                  gelaters. Vine a provar-los!
                </p>
              </li>
            </ul>
          </div>

          <div
            className="glide__bullets flex justify-center"
            data-glide-el="controls[nav]"
          >
            <button
              className="glide__bullet bg-gray-300 w-4 h-4 mr-4 rounded-full"
              data-glide-dir="=0"
            ></button>
            <button
              className="glide__bullet bg-gray-300 w-4 h-4 mr-4 rounded-full"
              data-glide-dir="=1"
            ></button>
            <button
              className="glide__bullet bg-gray-300 w-4 h-4 mr-4 rounded-full"
              data-glide-dir="=2"
            ></button>
          </div>
        </div>
      </section>

      <section id="onsom" className="max-w-screen-lg mx-auto px-4 py-8">
        <h2 className="text-lime-500 uppercase font-bold text-3xl pb-4">
          On som
        </h2>
        <p className="pt-3 pb-5">
          Actualment disposem d'un local a Girona. Ens podeu trobar a les
          escales de Sant Fèlix, al vell mig del Barri Vell de Girona.
        </p>
        <div className="md:flex md:space-x-4 space-y-4">
          <Img
            className="w-full md:w-1/2"
            fluid={data.lleona.childImageSharp.fluid}
          />
          <Img
            className="w-full md:w-1/2"
            fluid={data.stfelix.childImageSharp.fluid}
          />
        </div>
      </section>

      <section id="contacte" className="max-w-screen-lg mx-auto px-4 py-8">
        <h2 className="text-lime-500 uppercase font-bold text-3xl pb-4">
          Contacte
        </h2>

        <div className="w-full">
          <div className="md:flex md:space-x-6">
            <div className="mb-4">
              <h2 className="text-2xl font-medium text-gray-900">
                La Lletera (Cul de la Lleona)
              </h2>
              <div className="mt-3">
                <p className="text-lg text-gray-500">Carrer de la Barca nº 1</p>
                <p className="text-lg text-gray-500">17004 (Girona)</p>
              </div>
              <div className="mt-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg
                      className="h-6 w-6 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>
                  </div>
                  <div className="ml-3 text-base text-gray-500">
                    <p>
                      <a href="tel:+34972412419">+34 972 41 24 19</a>
                    </p>
                  </div>
                </div>
                <div className="mt-2 flex hidden">
                  <div className="flex-shrink-0">
                    <svg
                      className="h-6 w-6 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                  </div>
                  <div className="ml-3 text-base text-gray-500">
                    <p>
                      <a href="mailto:info@lalletera.cat">info@lalletera.cat</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <iframe
              className="flex-1 w-full"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2965.5223047461714!2d2.8242980189785496!3d41.989064986243214!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3aa872b77e617c51!2sLa%20Lletera%20gelats%20i%20iogurts%20artesans!5e0!3m2!1sca!2ses!4v1606838460615!5m2!1sca!2ses"
              width="600"
              height="450"
              frameborder="0"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
